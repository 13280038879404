import React from 'react';
import './window.scss';
import GoogleChrome from './google-chrome/GoogleChrome';
import Mail from './mail/Mail';
import Paint from './paint/Paint';
import useStore from '../../useStore';

function Window(props) {
	let id = props.id;
    let name = props.name;
	let appSpecific = props.appSpecific;
	let application;

	// Applications store
	const applicationRunning = useStore.applications((state) => state.running);
	const currentApplication = applicationRunning.find(application => application.id === id);

	switch (name) {
		case 'google-chrome':
			application = <GoogleChrome id={id} appSpecific={appSpecific} />;
			break;
		case 'mail':
			application = <Mail id={id} appSpecific={appSpecific} />;
			break;
		case 'paint':
			application = <Paint id={id} appSpecific={appSpecific} />;
			break;
	}

	return (
		<>
			<div className='window' style={{zIndex: currentApplication.order}}>
                {application}
			</div>
		</>
	);
}

export default Window;