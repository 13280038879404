import React, { useState, useRef } from 'react';
import Draggable from 'react-draggable';
import './mail.scss';
import useStore from '../../../useStore';
import dayjs from 'dayjs';

function Mail(props) {
	let id = props.id;
	let appSpecific = props.appSpecific;

	const refFromInput = useRef(null);
	const refSubjectInput = useRef(null);
	const refContentInput = useRef(null);

	let startMail;

	if (appSpecific) {
		startMail = appSpecific.startMail ? appSpecific.startMail : null;
	}

	// Global store
	const globalTotalWindowCount = useStore.global((state) => state.totalWindowCount);
	const globalPatchTotalWindowCount = useStore.global((state) => state.patchTotalWindowCount);

	// Notifications store
	const notificationAddQueue = useStore.notifications((state) => state.addQueue);

	// Applications store
	const applicationRunning = useStore.applications((state) => state.running);
	const applicationRemoveRunning = useStore.applications((state) => state.removeRunning);
	const applicationPatchMaximised = useStore.applications((state) => state.patchMaximised);
	const applicationPatchMinimised = useStore.applications((state) => state.patchMinimised);
	const applicationPatchOrder = useStore.applications((state) => state.patchOrder);
	const applicationPatchOpening = useStore.applications((state) => state.patchOpening);
	const applicationPatchWaiting = useStore.applications((state) => state.patchWaiting);
	const currentApplication = applicationRunning.find(application => application.id === id);

	// Mail store
	const mailInbox = useStore.mail((state) => state.inbox);
	const mailDrafts = useStore.mail((state) => state.drafts);
	const mailSent = useStore.mail((state) => state.sent);
	const mailAddInbox = useStore.mail((state) => state.addInbox);
	const mailAddDraft = useStore.mail((state) => state.addDraft);
	const mailAddSent = useStore.mail((state) => state.addSent);
	const mailPatchDraft = useStore.mail((state) => state.patchDraft);
	const mailRemoveDraft = useStore.mail((state) => state.removeDraft);
	const mailPatchRead = useStore.mail((state) => state.patchRead);
	const mailGetUnread = useStore.mail((state) => state.getUnread);
	const mailGetInboxCount = useStore.mail((state) => state.getInboxCount);

	// Component state
	// const [openMail, setOpenMail] = useState(mailInbox[0]);
	const [openMail, setOpenMail] = useState(startMail ? startMail : null);
	const [openDraft, setOpenDraft] = useState(false);
	const [openSent, setOpenSent] = useState(false);
	const [openFolder, setOpenFolder] = useState('inbox');
	const [composeMode, setComposeMode] = useState(null);
	const [draftContent, setDraftContent] = useState(null);
	const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

	const handleClick = (action, mailIndex = null, folder = null) => {
		switch (action) {
			case 'new-mail':
				setComposeMode('new');

				setTimeout(() => {
					refFromInput.current.value = '';
					refSubjectInput.current.value = '';
					refContentInput.current.value = '';
				}, 10);
				
				break;
			case 'change-folder':
				if (folder == 'drafts' && mailDrafts.length == 0) {
					return;
				}

				if (folder == 'drafts' && mailDrafts.length > 0) {
					if (!openDraft) {
						setOpenDraft(mailDrafts[0]);
					}
					setOpenFolder(folder);
					setComposeMode('draft');
					checkComposeMode();
					break;
				}

				if (folder == 'sent' && mailSent.length == 0) {
					return;
				}

				if (folder == 'sent' && mailSent.length > 0) {
					if (!openSent) {
						setOpenSent(mailSent[0]);
					}
					setOpenFolder(folder);
					setComposeMode(false);
					checkComposeMode();
					break;
				}

				setOpenFolder(folder);
				setComposeMode(false);
				checkComposeMode();
				break;
			case 'collapse-sidebar':
				setSidebarCollapsed(!sidebarCollapsed);
				break;
			case 'open-mail':
				mailPatchRead(mailIndex);
				setOpenMail(mailInbox[mailIndex]);
				setComposeMode(false);
				checkComposeMode();
				break;
			case 'open-draft':
				setOpenDraft(mailDrafts[mailIndex]);
				setComposeMode('draft');
				checkComposeMode();
				break;
			case 'open-sent':
				setOpenSent(mailSent[mailIndex]);
				setComposeMode(false);
				checkComposeMode();
				break;
			case 'focus':
				updateOrder();
				break;
			case 'close':
				applicationPatchWaiting(id);

				setTimeout(() => {
					checkComposeMode();
					applicationRemoveRunning(id);
				}, 200);

				break;
			case 'maximise':
				applicationPatchMaximised(id);
				break;
			case 'minimise':
				applicationPatchMinimised({ id: id, minimised: true });
				globalPatchTotalWindowCount();
				break;
			case 'post-email':
				postEmail();
				setOpenFolder('inbox');
				setComposeMode(false);
		}
	}

	const checkComposeMode = () => {
		if (composeMode == 'new') {
			const mail = {
				from: refFromInput.current.value.trim(),
				subject: refSubjectInput.current.value.trim(),
				content: refContentInput.current.value.trim(),
				time: dayjs().format('h:mm A')
			}

			mailAddDraft(mail);
		} else {
			if (draftContent) {
				mailPatchDraft(draftContent);
			}
		}
	}

	const handleChange = (event, action, input = null) => {
		switch (action) {
			case 'update-draft-content':
				let openDraftTemp = {
					content: openDraft.content,
					from: openDraft.from,
					id: openDraft.id,
					subject: openDraft.subject,
					time : openDraft.time
				}

				openDraftTemp[input] = event.target.value

				mailPatchDraft(openDraftTemp)
				setOpenDraft(openDraftTemp)

				const mail = {
					id: openDraft.id,
					from: refFromInput.current.value.trim(),
					subject: refSubjectInput.current.value.trim(),
					content: refContentInput.current.value.trim(),
					time: dayjs().format('h:mm A')
				}

				setDraftContent(mail);
				break;
		}
	}

	const handleMouseDown = (action) => {
		switch (action) {
			case 'focus':
				updateOrder();
				break;
		}
	}

	const handleDoubleClick = (action) => {
		switch (action) {
			case 'maximise':
				applicationPatchMaximised(id);
				break;
		}
	}
	
	const updateOrder = () => {
		applicationPatchOrder({ id: id, order: globalTotalWindowCount });
		globalPatchTotalWindowCount();
	}

	setTimeout(() => {
		applicationPatchOpening(id);
	}, 400);

	function postEmail() {
		const from = refFromInput.current.value.trim();
		const subject = refSubjectInput.current.value.trim();
		const content = refContentInput.current.value.trim();

		const data = {
			from: from,
			subject: subject,
			content: content
		}

		fetch(`https://api.yohanesmar.io/mail`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(data)
			})
			.then(response => response.json())
			.then(data => {
				switch (data.status) {
					case 'message_sent':
						mailAddInbox(
							{
								sender: ['Yohanes', 'Mario'],
								subject: "Message received",
								time: dayjs().format('D/MM/YYYY h:mm A'),
								content: 
								`Thank you for your message. I'll get back to you as soon as possible.
		
								Cheers,
								Yohan`
							}
						)
		
						mailAddSent(
							{
								from: from,
								subject: subject,
								content: content,
								time: dayjs().format('D/MM/YYYY h:mm A')
							}
						)
		
						notificationAddQueue(
							{
								application: 'mail',
								label: 'Mail',
								icon: 'mail',
								content: <>
									<p style={{fontSize: '13px', fontWeight: '600', marginBottom: '3px'}}>Yohanes Mario</p>
									<p style={{fontSize: '13px'}}>I got your message and I'll get back to you as soon as possible. Kind regards, Yohan</p>
								</>,
								notificationSpecific: { startMail: mailGetInboxCount() }
							}
						)
		
						if (composeMode == 'draft') {
							mailRemoveDraft(openDraft.id);
						}
						break;
					case 'no_content':
						mailAddInbox(
							{
								sender: ['Yohanes', 'Mario'],
								subject: "Did you forget to include a message?",
								content: 
								`It looks like your last email didn't include a message and was rejected by the server!
		
								Kind regards,
		
								Yohan`
							}
						)
		
						notificationAddQueue(
							{
								application: 'mail',
								label: 'Mail',
								icon: 'mail',
								content: <>
									<p style={{fontSize: '13px', fontWeight: '600', marginBottom: '3px'}}>Yohanes Mario</p>
									<p style={{fontSize: '13px'}}>It looks like your last email didn't include a message and was rejected by the server! Kind regards, Yohan</p>
								</>,
								notificationSpecific: { startMail: mailGetInboxCount() }
							}
						)
						break;
					case 'no_reply_email':
						mailAddInbox(
							{
								sender: ['Yohanes', 'Mario'],
								subject: "Did you forget your reply address?",
								content: 
								`It looks like your last email didn't include a reply address and was rejected by the server!
		
								Kind regards,
		
								Yohan`
							}
						)
		
						notificationAddQueue(
							{
								application: 'mail',
								label: 'Mail',
								icon: 'mail',
								content: <>
									<p style={{fontSize: '13px', fontWeight: '600', marginBottom: '3px'}}>Yohanes Mario</p>
									<p style={{fontSize: '13px'}}>It looks like your last email didn't include a reply address and was rejected by the server! Kind regards, Yohan</p>
								</>,
								notificationSpecific: { startMail: mailGetInboxCount() }
							}
						)
						break;
					case 'authentication_error':
						mailAddInbox(
							{
								sender: ['Yohanes', 'Mario'],
								subject: "Did you try to email me just now?",
								content: 
								`It seems your last email didn't go through, and I apologize for the inconvenience. It looks like there was an authentication error on my end.
		
								Cheers,
								Yohan`
							}
						)
		
						notificationAddQueue(
							{
								application: 'mail',
								label: 'Mail',
								icon: 'mail',
								content: <>
									<p style={{fontSize: '13px', fontWeight: '600', marginBottom: '3px'}}>Yohanes Mario</p>
									<p style={{fontSize: '13px'}}>It looks like your last email didn't go through, sorry! It appears to be an authentication error on my end. Kind regards, Yohan</p>
								</>,
								notificationSpecific: { startMail: mailGetInboxCount() }
							}
						)
						break;
				}
			})
			.catch(error => {
				mailAddInbox(
					{
						sender: ['Yohanes', 'Mario'],
						subject: "Did you try to email me just now?",
						content: 
						`It looks like your last email didn't go through, sorry!

						Kind regards,

						Yohan`
					}
				)

				notificationAddQueue(
					{
						application: 'mail',
						label: 'Mail',
						icon: 'mail',
						content: <>
							<p style={{fontSize: '13px', fontWeight: '600', marginBottom: '3px'}}>Yohanes Mario</p>
							<p style={{fontSize: '13px'}}>It looks like your last email didn't go through properly, sorry! Kind regards, Yohan</p>
						</>,
						notificationSpecific: { startMail: mailGetInboxCount() }
					}
				)
			});
	}

	return (
		<>
			<Draggable handle='.title-bar' disabled={currentApplication.maximised}>
				<div className={`mail ${currentApplication.opening ? 'opening' : ''} ${currentApplication.maximised ? 'maximised' : ''} ${currentApplication.minimised ? 'minimised' : ''} ${currentApplication.unminimising ? 'unminimising' : ''} ${currentApplication.waiting ? 'closing' : ''}`}>
					<div className='title-bar' onDoubleClick={() => handleDoubleClick('maximise')} onMouseDown={() => handleMouseDown('focus')}>
						<div className='title-bar-left'>
							<div className={`window-label ${sidebarCollapsed ? 'collapsed' : ''}`}>
								<p className='label'>Inbox - Guest</p>
							</div>

							<div className='list-container-extension'></div>
						</div>

						<div className='window-controls'>
							<button onClick={() => handleClick('minimise')}>
								<img src='/img/application/minimise.svg'></img>
							</button>

							<button className='maximise-window' onClick={() => handleClick('maximise')}>
								<img src={`/img/application/${currentApplication.maximiseLabel}.svg`}></img>
							</button>

							<button className='close-window' onClick={() => handleClick('close')}>
								<img src={`/img/application/close.svg`}></img>
							</button>
						</div>
					</div>

					<div className='main-container'>
						<div className={`accounts-sidebar ${sidebarCollapsed ? 'collapsed' : ''}`}>
							<div className='item' onClick={() => handleClick('collapse-sidebar')}>
								<div className='icon'>
									<img src='/img/application/burger.svg'></img>
								</div>
							</div>

							<div className='item new-mail' onClick={() => {handleClick('new-mail')}}>
								<div className='icon'>
									<img src='/img/application/plus.svg'></img>
								</div>
								<p className='label'>New mail</p>
							</div>

							<div className='item accounts no-hover'>
								<div className='icon'>
									<img src='/img/application/mail/accounts.svg'></img>
								</div>
								<p className='label'>Accounts</p>
							</div>

							<div className='item email-account' onClick={() => handleClick('change-folder', null, 'inbox')}>
								<div className='icon'></div>
								<div className='email-container'>
									<p className='user selected'><span>Guest</span> <span className='count'>{ mailGetUnread() > 0 ? mailGetUnread() : '' }</span></p>
									<p className='email'>guest@yohanesmar.io</p>
								</div>
							</div>

							<div className='item folders no-hover'>
								<div className='icon'>
									<img src='/img/application/mail/folder.svg'></img>
								</div>
								<p className='label'>Folders</p>
							</div>
							
							<div className={`item folder ${openFolder == 'inbox' ? 'selected' : ''}`} onClick={() => handleClick('change-folder', null, 'inbox')}>
								<div className='icon'></div>
								<p className='label'><span>Inbox</span> <span className='count'>{ mailGetUnread() > 0 ? mailGetUnread() : '' }</span></p>
							</div>

							<div className={`item folder ${openFolder == 'drafts' ? 'selected' : ''}`} onClick={() => handleClick('change-folder', null, 'drafts')}>
								<div className='icon'></div>
								<p className='label'><span>Drafts</span> <span className='count'>{ mailDrafts.length > 0 ? mailDrafts.length : '' }</span></p>
							</div>

							<div className={`item folder ${openFolder == 'sent' ? 'selected' : ''}`} onClick={() => handleClick('change-folder', null, 'sent')}>
								<div className='icon'></div>
								<p className='label'><span>Sent Mail</span> <span className='count'>{ mailSent.length > 0 ? mailSent.length : '' }</span></p>
							</div>
						</div>

						<div className='list-container'>
							{
								openFolder == 'inbox' ?
									<>
										<h3 className='folder-label'>Inbox</h3>

										{
											mailInbox.map((mail, index) => {
												return (
													<div className={`mail-item ${openMail && openMail.id == index ? 'selected' : ''}`} key={index} onClick={() => handleClick('open-mail', mail.id)}>
														<div className='avatar'>
															<p>{ mail.sender[0][0] }{ mail.sender[1][0] }</p>
														</div>
														
														<div className={`mail-item-right ${mail.read == true ? 'read' : ''}`}>
															<p className='sender'>{ mail.sender[0] } { mail.sender[1] }</p>
															<p className='subject'>{ mail.subject }</p>
															<p className='content'>{ mail.content }</p>
														</div>
													</div>
												)
											})
										}
									</>
								: openFolder == 'drafts' ?
									<>
										<h3 className='folder-label'>Drafts</h3>

										{
											mailDrafts.map((mail, index) => {
												return (
													<div className={`mail-item ${openDraft && openDraft.id == index ? 'selected' : ''}`} key={index} onClick={() => handleClick('open-draft', mail.id)}>
														<div className={`avatar draft ${mail.from ? 'has-from' : ''}`}>
															{
																mail.from ?
																	<>
																		<p>{ mail.from[0]}</p>
																	</>
																:
																	<>
																		<img src='/img/application/mail/accounts.svg'></img>
																	</>
															}
														</div>
														
														<div className='mail-item-right draft'>
															<p className='from'><span className='draft'>&#91;Draft&#93;</span> { mail.from ? mail.from : '(none)' }</p>
															<p className='subject'><span className='name'>{ mail.subject ? mail.subject : '(no subject)' }</span> <span className='time'>{ mail.time }</span></p>
															{ 
																mail.content ?
																	<>
																		<p className='content'>{ mail.content }</p>
																	</>
																:
																	''
															}
															
														</div>
													</div>
												)
											})
										}
									</>
								:
									<>
										<h3 className='folder-label'>Sent Mail</h3>

										{
											mailSent.map((mail, index) => {
												return (
													<div className={`mail-item ${openSent.id == index ? 'selected' : ''}`} key={index} onClick={() => handleClick('open-sent', mail.id)}>
														<div className='avatar'>
															<p>{ mail.from[0] ? mail.from[0] : '?' }</p>
														</div>
														
														<div className='mail-item-right'>
															<p className='sender'>{ mail.from[0] ? mail.from[0] : '(none)' }</p>
															<p className='subject'>{ mail.subject ? mail.subject : '(no subject)'}</p>
															<p className='content'>{ mail.content ? mail.content : '(Empty)'}</p>
														</div>
													</div>
												)
											})
										}
									</>
							}
						</div>

						<div className={`content-container ${sidebarCollapsed ? 'expanded' : ''}`}>
							{
								composeMode == 'draft' ?
									<div className='draft'>
										<div className='controls'>
											<div className='button send' onClick={() => {handleClick('post-email')}}>
												<img src='/img/application/mail/send.svg' />
												<p className='button-label'>Send</p>
											</div>
										</div>

										<div className='header'>
											<div className='input-row from'>
												<p className='label'>From:</p>
												<input id='from-input' type='text' className='value' ref={refFromInput} value={openDraft.from} onChange={(event) => handleChange(event, 'update-draft-content', 'from')}></input>
											</div>

											<div className='input-row'>
												<p className='label'>To: <span className='value'>Yohanes Mario</span></p>
											</div>

											<div className='input-row'>
												<input id='subject-input' type='text' className='value' ref={refSubjectInput} value={openDraft.subject} placeholder='Subject' onChange={(event) => handleChange(event, 'update-draft-content', 'subject')}></input>
											</div>
										</div>

										<textarea id='content-input' ref={refContentInput} value={openDraft.content} onChange={(event) => handleChange(event, 'update-draft-content', 'content')}></textarea>
									</div>
								: composeMode == 'new' ?
									<div className='new-mail'>
										<div className='controls'>
											<div className='button send' onClick={() => {handleClick('post-email')}}>
												<img src='/img/application/mail/send.svg' />
												<p className='button-label'>Send</p>
											</div>
										</div>

										<div className='header'>
											<div className='input-row from'>
												<p className='label'>From:</p>
												<input id='from-input' type='text' className='value' ref={refFromInput}></input>
											</div>

											<div className='input-row'>
												<p className='label'>To: <span className='value'>Yohanes Mario</span></p>
											</div>

											<div className='input-row'>
												<input id='subject-input' type='text' className='value' ref={refSubjectInput} placeholder='Subject'></input>
											</div>
										</div>

										<textarea id='content-input' ref={refContentInput}></textarea>
									</div>
								: openFolder == 'sent' ?
									<div className='open-mail'>
										<h3 className='subject'>{ openSent.subject ? openSent.subject : '(no subject)' }</h3>

										<div className='sender-info-container'>
											<div className='avatar-container'>
												<div className='avatar'>
													<p className='initials'>{ openSent.from[0] ? openSent.from[0] : '?' }</p>
												</div>
											</div>

											<div className='info-container'>
												<p className='sender'>Guest &lt;{ openSent.from ? openSent.from : 'no-email' }&gt;</p>
												<p className='date'>{ openSent.time }</p>
											</div>
										</div>

										<p className='recipient'>To: <span className='email'>Yohanes Mario</span></p>
										<p className='content'>{ openSent.content ? openSent.content : '(Empty)' }</p>
									</div>
								: openMail == null && openFolder == 'inbox' ? 
									<div className='empty-overlay'>
										<img src='/img/application/mail/mail.svg' />
										<p>No mail selected</p>
									</div>
								:
									<div className='open-mail'>
										<h3 className='subject'>{ openMail.subject }</h3>

										<div className='sender-info-container'>
											<div className='avatar-container'>
												<div className='avatar'>
													<p className='initials'>{ openMail.sender[0][0] }{ openMail.sender[1][0] }</p>
												</div>
											</div>

											<div className='info-container'>
												<p className='sender'>Yohanes Mario</p>
												<p className='date'>{ openMail.time }</p>
											</div>
										</div>

										<p className='recipient'>To: <span className='email'>guest@yohanesmar.io</span></p>
										<p className='content'>{ openMail.content }</p>

										<img className='false-signature' src='/img/application/mail/ymario_signature.png'></img>
										<p className='signature line-1'>Lead Front-end Developer @ Boss Capital Holdings</p>
										<p className='signature line-2'>Brisbane, Australia</p>
										<ul className='signature-list'>
											<li>
												<a href='https://www.linkedin.com/in/ymario-dev' target='_blank'>LinkedIn</a>
											</li>
										</ul>
									</div>
							}
						</div>
					</div>
				</div>
			</Draggable>
		</>
	);
}

export default Mail;