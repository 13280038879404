import React, { useState, useEffect } from 'react';
import './widgets.scss';
import dayjs from 'dayjs';
import useStore from '../../../../useStore';

function Widgets(props) {
	const [weather, setWeather] = useState(null);
	const [news, setNews] = useState(null);

	// Widgets store
	const widgetsWeather = useStore.widgets((state) => state.weather);
	const widgetsNews = useStore.widgets((state) => state.news);

	return (
		<>
			<div className='widgets'>
				<div className='widget-group'>
					<p className='group-label'>Life at a glance</p>

					<div className='widgets-container'>
						<div className='row'>
							<div className='widget date'>
								<p className='day'>{dayjs().format('dddd')}</p>
								<p className='date'>{dayjs().format('D')}</p>
							</div>

							<div className='widget weather'>
								{
									!widgetsWeather || typeof(widgetsWeather) === 'string' ? 
									
										<div className='loading'>
											{typeof(widgetsWeather) === 'string' ? <p>{widgetsWeather}</p> : <img src='/img/icon/spinner.svg' /> }
										</div>
									
									: 

										<>
											<p className='weather-short'>{widgetsWeather.weather[0].main}</p>

											<div className='weather-details'>
												<p className='temperature'>{parseInt(widgetsWeather.main.temp - 273.15)}&deg;</p>

												<div className='min-max'>
													<p>{parseInt(widgetsWeather.main.temp_max - 273.15)}&deg;</p>
													<p className='min'>{parseInt(widgetsWeather.main.temp_min - 273.15)}&deg;</p>
												</div>

												<div className='humidity-wind'>
													<div className='detail-container'>
														<img src='/img/start-menu/widgets/weather/humidity.svg' />
														<p>{parseInt(widgetsWeather.main.humidity)}%</p>
													</div>
													
													<div className='detail-container'>
													<img src='/img/start-menu/widgets/weather/wind.svg' />
														<p className='wind'>{parseFloat(widgetsWeather.wind.speed * 1.6093440006147).toFixed(1)} kmh</p>
													</div>
												</div>
											</div>

											<p className='weather-location'>{widgetsWeather.name}</p>
										</>
								}
							</div>
						</div>

						<div className='row'>
							<div className='widget news' style={widgetsNews && widgetsNews.articles && widgetsNews.articles[0].urlToImage ? {backgroundImage: `url(${widgetsNews.articles[0].urlToImage})`} : null}>
								{
										!widgetsNews || typeof(widgetsNews) === 'string' ? 
											
											<div className='loading'>
												{typeof(widgetsNews) === 'string' ? <p>{widgetsNews}</p> : <img src='/img/icon/spinner.svg' /> }
											</div>
										
										: 

											<>
												<div class='overlay'></div>
												<p className='headline'>{widgetsNews.articles[0].title}</p>

												<div class='push-down'>
													<p className='label'>News</p>
													<img src='/img/start-menu/widgets/news/news.svg' />
												</div>

												<a className='stretched-link' href={widgetsNews.articles[0].url}></a>
											</>
								}
							</div>

							{/* <div className='widget mail'>
								<img className='icon' src='/img/desktop/mail.svg' />
								<p className='label'>Mail</p>
							</div> */}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Widgets