import React from 'react';
import './taskbar-item.scss';
import useStore from '../../../useStore';

function TaskbarItem(props) {
	let id = props.id;
    let name = props.name;

	// Global store
	const globalTotalWindowCount = useStore.global((state) => state.totalWindowCount);
	const globalPatchTotalWindowCount = useStore.global((state) => state.patchTotalWindowCount);

	// StartMenu store
	const startMenuOpen = useStore.startMenu((state) => state.open);
	const startMenuPatchOpen = useStore.startMenu((state) => state.patchOpen);
	const startMenuPatchWaiting = useStore.startMenu((state) => state.patchWaiting);

	// Applications store
	const applicationRunning = useStore.applications((state) => state.running);
	const applicationPatchOrder = useStore.applications((state) => state.patchOrder);
	const applicationPatchMinimised = useStore.applications((state) => state.patchMinimised);
	const applicationPatchUnminimising = useStore.applications((state) => state.patchUnminimising);
	const currentApplication = applicationRunning.find(application => application.id === id);

	const handleClick = (action) => {
		switch (action) {
			case 'focus':
				if (startMenuOpen) {
					startMenuPatchWaiting(true);
					setTimeout(() => {
						startMenuPatchOpen(true);
						startMenuPatchWaiting(false);
					}, 200);

					return;
				}

				if (currentApplication.minimised) {
					applicationPatchMinimised({ id: id, minimised: false });
					applicationPatchUnminimising({ id: id, unminimising: true });

					setTimeout(() => {
						applicationPatchUnminimising({ id: id, unminimising: false });
					}, 400);
				} else if (currentApplication.order + 1 == globalTotalWindowCount) {
					applicationPatchMinimised({ id: id, minimised: true });
					globalPatchTotalWindowCount();
				}

				updateOrder();
				break;
		}
	}

	const updateOrder = () => {
		applicationPatchOrder({ id: id, order: globalTotalWindowCount });
		globalPatchTotalWindowCount();
	}

	return (
		<>
			<div className={`taskbar-item running ${currentApplication.waiting ? 'closing' : ''} ${currentApplication.minimised ? 'collapsed' : 'expanded'} ${currentApplication.order + 1 == globalTotalWindowCount ? 'active' : 'inactive'}`} onMouseDown={() => handleClick('focus')}>
				<div className='information-container'>
					<div className='icon-container'>
						<img src={`/img/icon/${name}.svg`} alt='icon' />
					</div>
					<p className='label'>{currentApplication.label}</p>
				</div>

				<div className='underline-container'>
					<div className={`underline ${currentApplication.order + 1 == globalTotalWindowCount ? 'active' : 'inactive'}`}></div>
				</div>
			</div>
		</>
	);
}

export default TaskbarItem