import React from 'react';
import './notification.scss';
import useStore from '../../useStore';

function Notification(props) {
	let id = props.id;
	let application = props.application;
	let label = props.label;
	let icon = props.icon;
	let content = props.content;
	let notificationSpecific = props.notificationSpecific;

	let notificationSpecificSorted = {};

	if (notificationSpecific) {
		switch (application) {
			case 'mail':
				notificationSpecificSorted.startMail = notificationSpecific.startMail ? notificationSpecific.startMail : null;
				break;
		}
	}

	// Global store
	const globalTotalWindowCount = useStore.global((state) => state.totalWindowCount);

	// Applications store
	const applicationAddRunning = useStore.applications((state) => state.addRunning);

	// Notifications store
	const notificationRemoveQueue = useStore.notifications((state) => state.removeQueue);

	// Mail store
	const mailInbox = useStore.mail((state) => state.inbox);
	const mailPatchRead = useStore.mail((state) => state.patchRead);

	setTimeout(() => {
		notificationRemoveQueue(id);
	}, 5000);

	const handleClick = (event, action = false) => {
		event.stopPropagation();
		
		switch (action) {
			case 'remove-notification':
				notificationRemoveQueue(id);
				break;
			case 'open-app':
				if (application == 'mail') {
					mailPatchRead(notificationSpecificSorted.startMail - 1);
					applicationAddRunning({ name: 'mail', order: globalTotalWindowCount, label: 'Mail', appSpecific: { startMail: mailInbox[notificationSpecificSorted.startMail - 1] } });
				}
				notificationRemoveQueue(id);
				break;
		}
	}

	return (
		<>
			<div className='notification' style={{zIndex: 100000 + id}} onClick={(event) => handleClick(event, 'open-app')}>
				<div className='title-bar'>
					<div className='application-info'>
						<img className='icon' src={`/img/icon/notification/${icon}.svg`} />
						<p className='label'>{ label }</p>
					</div>
					
					<div className='controls'>
						<div className='close-container' onClick={(event) => handleClick(event, 'remove-notification')}>
							<img className='close' src='/img/application/close-light.svg' />
						</div>
					</div>
				</div>

				<div className='content-container'>
					{content}
				</div>
			</div>
		</>
	);
}

export default Notification;