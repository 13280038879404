import React from 'react';
import Desktop from './desktop/Desktop';
import './app.scss';

function App() {
	return (
		<>
			<div className='app'>
				<Desktop />
			</div>
		</>
	);
}

export default App;
