import React from 'react';
import './desktop.scss';
import Icon from '../__components__/icon/Icon';
import Window from '../__components__/window/Window';
import useStore from '../useStore';
import Taskbar from '../__components__/taskbar/Taskbar';
import StartMenu from '../__components__/taskbar/start-menu/StartMenu';
import Notification from '../__components__/notification/Notification';
import Lockscreen from './lockscreen/Lockscreen';

function Desktop(props) {
	// Global store
	const globalPatchDatetime = useStore.global((state) => state.patchDatetime);
	const globalIsLoggedIn = useStore.global((state) => state.isLoggedIn);

	// Notification store
	const notificationQueue = useStore.notifications((state) => state.queue);

	// Icon store
	const iconSetSelected = useStore.icons((state) => state.setSelected);

	// StartMenue store
	const startMenuOpen = useStore.startMenu((state) => state.open);
	const startMenuPatchOpen = useStore.startMenu((state) => state.patchOpen);
	const startMenuPatchWaiting = useStore.startMenu((state) => state.patchWaiting);

	// Applications store
	const applicationRunning = useStore.applications((state) => state.running);

	setInterval(() => {
		globalPatchDatetime();
	}, 1000 * 60);

	const handleClick = (action) => {
		switch (action) {
			case 'focus':
				iconSetSelected(null);
				break;
		}
	}

	const handleMouseDown = (action) => {
		switch (action) {
			case 'focus':
				startMenuPatchWaiting(true);
				setTimeout(() => {
					startMenuPatchOpen(true);
					startMenuPatchWaiting(false);
				}, 200);

				break;
		}
	}

	const windowList = applicationRunning.map(application => {
		return <Window key={application.id} id={application.id} name={application.name} appSpecific={application.appSpecific} />
	})

	const notificationList = notificationQueue.map(notification => {
			return <Notification key={notification.id} id={notification.id} application={notification.application} label={notification.label} icon={notification.icon} content={notification.content} notificationSpecific={notification.notificationSpecific} />
	})

	return (
		<>
			<div className='desktop' onClick={() => handleClick('focus')} onMouseDown={() => handleMouseDown('focus')}>
				<Icon name='google-chrome' label='Chrome' isShortcut={false} />
				<Icon name='mail' label='Mail' isShortcut={false} />
				<Icon name='github' label='GitHub' isShortcut={true} url='https://github.com/sparepillowgit' />
				<Icon name='linkedin' label='LinkedIn' isShortcut={true} url='https://www.linkedin.com/in/ymario-dev/' />
				{/* <Icon name='paint' label='Paint' /> */}

				{windowList}
				{notificationList}
				
				<Taskbar />
				{startMenuOpen  ? <StartMenu /> : null}
				
				<div className='mobile-overlay'>
					<div className='background-container'></div>

					<div className='mobile-content'>
						<h3>Yohanes Mario</h3>
						<p className='title'>Lead Front-end Developer @ Boss Capital Holdings</p>
						<p>Hi, I'm Yohan. My portfolio is best viewed on a larger screen.</p>

						<div className='links'>
							<a href='https://www.linkedin.com/in/ymario-dev/'>LinkedIn</a>
						</div>
					</div>
				</div>
				
				{!globalIsLoggedIn ? <Lockscreen /> : null}
			</div>
		</>
	);
}

export default Desktop;