import React, { useState, useRef } from 'react';
import Draggable from 'react-draggable';
import './paint.scss';
import useStore from '../../../useStore';
import SignatureCanvas from 'react-signature-canvas';
import FileSaver from 'file-saver';

function Paint(props) {
	let id = props.id;

	// Global store
	const globalTotalWindowCount = useStore.global((state) => state.totalWindowCount);
	const globalPatchTotalWindowCount = useStore.global((state) => state.patchTotalWindowCount);

	// Applications store
	const applicationRunning = useStore.applications((state) => state.running);
	const applicationRemoveRunning = useStore.applications((state) => state.removeRunning);
	const applicationPatchMaximised = useStore.applications((state) => state.patchMaximised);
	const applicationPatchMinimised = useStore.applications((state) => state.patchMinimised);
	const applicationPatchOrder = useStore.applications((state) => state.patchOrder);
	const applicationPatchOpening = useStore.applications((state) => state.patchOpening);
	const applicationPatchWaiting = useStore.applications((state) => state.patchWaiting);
	const currentApplication = applicationRunning.find(application => application.id === id);

	let canvasRef = useRef(null);
	let dClickStop = false;

	const [selectedSize, setSelectedSize] = useState(0.5);
	const [selectedTool, setSelectedTool] = useState('pencil');
	const [selectedColor, setSelectedColor] = useState('#000000');

	const resizeCanvas = (pad, canvas, data) => {
		setTimeout(() => {
			canvas.height = canvas.parentNode.parentNode.offsetHeight - 130;
			canvas.width = canvas.parentNode.parentNode.offsetWidth;

			pad.clear();

			setTimeout(() => {
				pad.fromData(data, {ratio: 1, width: canvas.width, height: canvas.height});
			}, 1);
		}, 50);
	}

	const handleClick = (event, action, value = null) => {
		const canvas = canvasRef.getCanvas();
		const pad = canvasRef.getSignaturePad()
		const data = pad.toData();
		const context = canvas.getContext('2d');

		switch (action) {
			case 'select-brush':
				setSelectedSize(value);
				break;
			case 'select-color':
				setSelectedColor(value);
				break;
			case 'focus':
				updateOrder();
				break;
			case 'close':
				applicationPatchWaiting(id);
				setTimeout(() => {
					applicationRemoveRunning(id);
				}, 200);
				break;
			case 'maximise':
				applicationPatchMaximised(id);
				resizeCanvas(pad, canvas, data);
				
				break;
			case 'minimise':
				applicationPatchMinimised({ id: id, minimised: true });
				globalPatchTotalWindowCount();
				break;
			case 'pencil':
				setSelectedTool(action);
				context.globalCompositeOperation = 'source-over';
				break;
			case 'erase':
				setSelectedTool(action);
				context.globalCompositeOperation = 'destination-out';
				break;
			case 'new':
				pad.clear();
				break;
			case 'save':
				FileSaver.saveAs(pad.toDataURL('image/png'), "Untitled.png");
				break;
			case 'undo':
				dClickStop = true;

				setTimeout(() => {
					dClickStop = false;	
				}, 400);

				if (data) {
					data.pop();
					pad.fromData(data);
				}
				break;
		}
	}

	const handleMouseDown = (action) => {
		switch (action) {
			case 'focus':
				updateOrder();
				break;
		}
	}

	const handleDoubleClick = (action) => {
		const canvas = canvasRef.getCanvas();
		const pad = canvasRef.getSignaturePad()
		const data = pad.toData();

		switch (action) {
			case 'maximise':
				if (dClickStop) return;

				applicationPatchMaximised(id);
				resizeCanvas(pad, canvas, data);
				
				break;
		}
	}

	const updateOrder = () => {
		applicationPatchOrder({ id: id, order: globalTotalWindowCount });
		globalPatchTotalWindowCount();
	}

	setTimeout(() => {
		applicationPatchOpening(id);
	}, 400);

	return (
		<>
			<Draggable handle='.title-bar' disabled={currentApplication.maximised}>
				<div className={`paint ${currentApplication.opening ? 'opening' : ''} ${currentApplication.maximised ? 'maximised' : ''} ${currentApplication.minimised ? 'minimised' : ''} ${currentApplication.unminimising ? 'unminimising' : ''} ${currentApplication.waiting ? 'closing' : ''}`}>
					<div className='title-bar' onDoubleClick={() => handleDoubleClick('maximise')} onMouseDown={() => handleMouseDown('focus')}>
						<div className='title-bar-left'>
							<div className='window-label'>
								<img class='icon' src='/img/icon/paint.svg' alt='Paint' />

								<div className='paint-controls'>
									<div className='control new' onClick={(event) => handleClick(event, 'new')}>
										<img className='icon' src='/img/application/paint/new.svg' alt='New' />
									</div>

									<div className='control save' onClick={(event) => handleClick(event, 'save')}>
										<img className='icon' src='/img/application/paint/save.svg' alt='Save' />
									</div>

									<div className='control undo' onClick={(event) => handleClick(event, 'undo')}>
										<img className='icon' src='/img/application/paint/undo.svg' alt='Undo' />
									</div>
								</div>

								<p class='label'>Untitled - Paint</p>
							</div>
						</div>

						<div className='window-controls'>
							<button onClick={(event) => handleClick(event, 'minimise')}>
								<img src='/img/application/minimise.svg'></img>
							</button>

							<button className='maximise-window' onClick={(event) => handleClick(event, 'maximise')}>
								<img src={`/img/application/${currentApplication.maximiseLabel}.svg`}></img>
							</button>

							<button className='close-window' onClick={(event) => handleClick(event, 'close')}>
								<img src={`/img/application/close.svg`}></img>
							</button>
						</div>
					</div>

					<div className='toolbar'>
						<div className='sub-container'>
							<div className='buttons'>
								<div className={`icon-container ${selectedTool == 'pencil' ? 'selected' : ''}`} onClick={(event) => handleClick(event, 'pencil')}>
									<img src='/img/application/paint/pencil.svg' alt='Pencil' />
								</div>

								<div className={`icon-container ${selectedTool == 'erase' ? 'selected' : ''}`} onClick={(event) => handleClick(event, 'erase')}>
									<img src='/img/application/paint/eraser.svg' alt='Erase' />
								</div>
							</div>

							<p className='label'>Tools</p>
						</div>

						<div className='sub-container'>
							<div className='buttons'>
								<div className='brush-container'>
									<div className='brush-size s1'></div>
									<div className='brush-size s2'></div>
									<div className='brush-size s3'></div>
									<div className='brush-size s4'></div>
									<div className='brush-size s5'></div>

									<div class='selection-container'>
										<div className={`brush-size-container ${selectedSize === 0.5 ? 'selected' : ''}`} onClick={(event) => handleClick(event, 'select-brush', 0.5)}>
											<div className='brush s1'></div>
										</div>

										<div className={`brush-size-container ${selectedSize === 1 ? 'selected' : ''}`} onClick={(event) => handleClick(event, 'select-brush', 1)}>
											<div className='brush s2'></div>
										</div>

										<div className={`brush-size-container ${selectedSize === 2 ? 'selected' : ''}`} onClick={(event) => handleClick(event, 'select-brush', 2)}>
											<div className='brush s3'></div>
										</div>

										<div className={`brush-size-container ${selectedSize === 3 ? 'selected' : ''}`} onClick={(event) => handleClick(event, 'select-brush', 3)}>
											<div className='brush s4'></div>
										</div>
									</div>
								</div>
							</div>

							<p className='label'>Size</p>
						</div>

						<div className='sub-container'>
							<div className='keep-up'>
								<div className='color-main'>
									<div className='color-main-outer'>
										<div className='color-main-inner' style={{backgroundColor: selectedColor}}></div>
									</div>
									
									<p className='label'>Colour</p>
								</div>

								<div className='buttons colors'>
									<div className={`color-container`} onClick={(event) => handleClick(event, 'select-color', '#000000')}>
										<div className='color-inner c000000'></div>
									</div>

									<div className={`color-container`} onClick={(event) => handleClick(event, 'select-color', '#7f7f7f')}>
										<div className='color-inner c7f7f7f'></div>
									</div>

									<div className={`color-container`} onClick={(event) => handleClick(event, 'select-color', '#880015')}>
										<div className='color-inner c880015'></div>
									</div>

									<div className={`color-container`} onClick={(event) => handleClick(event, 'select-color', '#ed1c24')}>
										<div className='color-inner ced1c24'></div>
									</div>

									<div className={`color-container`} onClick={(event) => handleClick(event, 'select-color', '#ff7f27')}>
										<div className='color-inner cff7f27'></div>
									</div>

									<div className={`color-container`} onClick={(event) => handleClick(event, 'select-color', '#fff200')}>
										<div className='color-inner cfff200'></div>
									</div>

									<div className={`color-container`} onClick={(event) => handleClick(event, 'select-color', '#22b14c')}>
										<div className='color-inner c22b14c'></div>
									</div>

									<div className={`color-container`} onClick={(event) => handleClick(event, 'select-color', '#00a2e8')}>
										<div className='color-inner c00a2e8'></div>
									</div>

									<div className={`color-container`} onClick={(event) => handleClick(event, 'select-color', '#3f48cc')}>
										<div className='color-inner c3f48cc'></div>
									</div>

									<div className={`color-container`} onClick={(event) => handleClick(event, 'select-color', '#a349a4')}>
										<div className='color-inner ca349a4'></div>
									</div>

									<div className={`color-container`} onClick={(event) => handleClick(event, 'select-color', '#ffffff')}>
										<div className='color-inner cffffff'></div>
									</div>

									<div className={`color-container`} onClick={(event) => handleClick(event, 'select-color', '#c3c3c3')}>
										<div className='color-inner cc3c3c3'></div>
									</div>

									<div className={`color-container`} onClick={(event) => handleClick(event, 'select-color', '#b97a57')}>
										<div className='color-inner cb97a57'></div>
									</div>

									<div className={`color-container`} onClick={(event) => handleClick(event, 'select-color', '#ffaec9')}>
										<div className='color-inner cffaec9'></div>
									</div>

									<div className={`color-container`} onClick={(event) => handleClick(event, 'select-color', '#ffc90e')}>
										<div className='color-inner cffc90e'></div>
									</div>

									<div className={`color-container`} onClick={(event) => handleClick(event, 'select-color', '#efe4b0')}>
										<div className='color-inner cefe4b0'></div>
									</div>

									<div className={`color-container`} onClick={(event) => handleClick(event, 'select-color', '#b5e61d')}>
										<div className='color-inner cb5e61d'></div>
									</div>

									<div className={`color-container`} onClick={(event) => handleClick(event, 'select-color', '#99d9ea')}>
										<div className='color-inner c99d9ea'></div>
									</div>

									<div className={`color-container`} onClick={(event) => handleClick(event, 'select-color', '#7092be')}>
										<div className='color-inner c7092be'></div>
									</div>

									<div className={`color-container`} onClick={(event) => handleClick(event, 'select-color', '#c8bfe7')}>
										<div className='color-inner cc8bfe7'></div>
									</div>
								</div>
							</div>

							<p className='label'>Colours</p>
						</div>
					</div>

					<div className='canvas-container'>
						<SignatureCanvas penColor={selectedColor} throttle={8} backgroundColor={'#ffffff'} minWidth={selectedSize} maxWidth={selectedSize} minDistance={0} ref={(ref) => { canvasRef = ref }} clearOnResize={false} canvasProps={{className: 'canvas'}} />
					</div>
				</div>
			</Draggable>
		</>
	);
}

export default Paint;