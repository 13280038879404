import React, { useState, useRef } from 'react';
import Draggable from 'react-draggable';
import './google-chrome.scss';
import useStore from '../../../useStore';

function GoogleChrome(props) {
	let id = props.id;
	let appSpecific = props.appSpecific;

	// Component state
	const [url, setUrl] = useState('/portfolio/new-tab.html');
	const [iframeClasses, setiframeClasses] = useState('');

	// Global store
	const globalTotalWindowCount = useStore.global((state) => state.totalWindowCount);
	const globalPatchTotalWindowCount = useStore.global((state) => state.patchTotalWindowCount);

	// Applications store
	const applicationRunning = useStore.applications((state) => state.running);
	const applicationRemoveRunning = useStore.applications((state) => state.removeRunning);
	const applicationPatchLabel = useStore.applications((state) => state.patchLabel);
	const applicationPatchMaximised = useStore.applications((state) => state.patchMaximised);
	const applicationPatchMinimised = useStore.applications((state) => state.patchMinimised);
	const applicationPatchOrder = useStore.applications((state) => state.patchOrder);
	const applicationPatchOpening = useStore.applications((state) => state.patchOpening);
	const applicationPatchWaiting = useStore.applications((state) => state.patchWaiting);
	const currentApplication = applicationRunning.find(application => application.id === id);

	const addressBar = useRef(null);

	const handleClick = (action) => {
		switch (action) {
			case 'reload':
				const tempStore = document.querySelector('iframe').contentWindow.location.href;
				setUrl(null);
				setTimeout(() => {
					setUrl(tempStore);
				}, 10);
				break;
			case 'home':
				setUrl(null);
				setTimeout(() => {
					setUrl(`/portfolio/new-tab.html`);
					applicationPatchLabel({ id: id, label: 'Google Chrome' });
					addressBar.current.value = '';
				}, 10);
				break;
			case 'focus':
				updateOrder();
				break;
			case 'close':
				applicationPatchWaiting(id);
				setTimeout(() => {
					applicationRemoveRunning(id);
				}, 200);
				break;
			case 'maximise':
				applicationPatchMaximised(id);
				break;
			case 'minimise':
				applicationPatchMinimised({ id: id, minimised: true });
				globalPatchTotalWindowCount();
				break;
		}
	}

	const handleMouseDown = (action) => {
		switch (action) {
			case 'focus':
				updateOrder();
				break;
		}
	}

	const handleDoubleClick = (action) => {
		switch (action) {
			case 'maximise':
				applicationPatchMaximised(id);
				break;
		}
	}

	const handleKeyPress = (event, action) => {
		setiframeClasses('');

		switch (action) {
			case 'visit-url':
				if (event.key === 'Enter') {
					let url = event.target.value;
					let label;

					switch (true) {
						case (url.endsWith('google.com') || url.endsWith('google.com/') || url.endsWith('google.com.au') || url.endsWith('google.com.au/')):
							url = `https://www.google.com/webhp?igu=1`;
							applicationPatchLabel({ id: id, label: 'Google Chrome' });
							break;
						case ((url.endsWith('.com') || url.endsWith('.com.au') || url.endsWith('.com/') || url.endsWith('.com.au/')) && (!url.startsWith('http://') && !url.startsWith('https://'))):
							label = url.replace('http://', '');
							label = url.replace('https://', '');
							if (label.endsWith('/')) {
								label = label.slice(0, -1);
							}
							label = label.replace('www.', '');
							if (url.endsWith('/')) {
								url = url.slice(0, -1);
							}
							url = `https://${url}/`;
							applicationPatchLabel({ id: id, label: label});
							break;
						case (url.startsWith('www.')):
							label = label.replace('www.', '');
							url = `https://${url}/`;
							applicationPatchLabel({ id: id, label: label});
							break;
						case (!url.startsWith('http://') && !url.startsWith('https://')):
							label = url;
							url = `https://www.google.com/search?q=${url}&igu=1`;
							applicationPatchLabel({ id: id, label: `${label} - Google Search` });
							break;
						case (url.startsWith('http://') || url.startsWith('https://')):
							if (url !== 'https://www.google.com/webhp?igu=1') {
								label = url;
								label = label.replace('http://', '');
								label = label.replace('https://', '');
								label = label.replace('www.', '');
								label = label.replace('webhp?igu=1', '');
								if (label.endsWith('/')) {
									label = label.slice(0, -1);
								}
								applicationPatchLabel({ id: id, label: `${label} - Google Search` });
							} else {
								applicationPatchLabel({ id: id, label: 'Google Chrome' });
							}
							
							break;
						default:
							url = `https://www.google.com/webhp?igu=1`;
							applicationPatchLabel({ id: id, label: 'Google Chrome' });
							break;
					}

				setUrl(url);
				event.target.value = url;
				break;
			}
		}
	}
	
	const updateOrder = () => {
		applicationPatchOrder({ id: id, order: globalTotalWindowCount });
		globalPatchTotalWindowCount();
	}

	setTimeout(() => {
		applicationPatchOpening(id);
	}, 400);

	return (
		<>
			<Draggable handle='.title-bar' disabled={currentApplication.maximised}>
				<div className={`google-chrome ${currentApplication.opening ? 'opening' : ''} ${currentApplication.maximised ? 'maximised' : ''} ${currentApplication.minimised ? 'minimised' : ''} ${currentApplication.unminimising ? 'unminimising' : ''} ${currentApplication.waiting ? 'closing' : ''}`}>
					<div className='title-bar' onDoubleClick={() => handleDoubleClick('maximise')} onMouseDown={() => handleMouseDown('focus')}>
						<div className='tab'>
							<div className='left-curve'>
								<div className='left-curve-inner'></div>
							</div>

							<div className='tab-information'>
								<div className='tab-icon'>
									<img src='/img/icon/google-chrome.svg' alt='Google Chrome' />
								</div>

								<div className='tab-title'>
									<p>{currentApplication.label}</p>
								</div>
							</div>

							<div className='right-curve'>
								<div className='right-curve-inner'></div>
							</div>
						</div>

						<div className='window-controls'>
							<button onClick={() => handleClick('minimise')}>
								<img src='/img/application/minimise.svg'></img>
							</button>

							<button className='maximise-window' onClick={() => handleClick('maximise')}>
								<img src={`/img/application/${currentApplication.maximiseLabel}.svg`}></img>
							</button>

							<button className='close-window' onClick={() => handleClick('close')}>
								<img src={`/img/application/close.svg`}></img>
							</button>
						</div>
					</div>

					<div className='browser-controls'>
						<div className='navigation-container'>
							{/* <div className='button-container'>
								<img src='/img/application/google-chrome/arrow.svg' />
							</div>

							<div className='button-container'>
								<img className='flip' src='/img/application/google-chrome/arrow.svg' />
							</div> */}
							
							<div className='button-container' onClick={() => {handleClick('home')}}>
								<img className='home' src='/img/application/google-chrome/home.svg' />
							</div>

							<div className='button-container' onClick={() => {handleClick('reload')}}>
								<img className='reload' src='/img/application/google-chrome/reload.svg' />
							</div>
						</div>

						<div className='address-bar-container'>
							<input id='url' type='text' ref={addressBar} className='address-bar' placeholder='Search Google or type a URL' onKeyPress={(event) => handleKeyPress(event, 'visit-url')} />
						</div>

						<div className='menus-container'>

						</div>
					</div>

					<iframe className={iframeClasses} src={url} title='Google Chrome'></iframe>
				</div>
			</Draggable>
		</>
	);
}

export default GoogleChrome;