import React from 'react';
import './system-tray-item.scss';

function SystemTrayItem(props) {
    let name = props.name;
	let title = props.title;

	return (
		<>
			<div className={`system-tray-item ${name}`} title={title}>
				<img src={`/img/icon/${name}.svg`} alt='icon' />
			</div>
		</>
	);
}

export default SystemTrayItem