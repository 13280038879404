import React, { useState, useEffect } from 'react';
import './lockscreen.scss';
import dayjs from 'dayjs';
import useStore from '../../useStore';

function Lockscreen(props) {
	const [anyKeyed, setAnyKeyed] = useState(false);
	const [wrongPassword, setWrongPassword] = useState(false);
	const [time, setTime] = useState(dayjs().format('h:mm'));
	const [date, setDate] = useState(dayjs().format('dddd, D MMMM'));
	const [hiding, setHiding] = useState(false);

	// Global store
	const globalPatchIsLoggedIn = useStore.global((state) => state.patchIsLoggedIn);

	// Notifications store
	const notificationAddQueue = useStore.notifications((state) => state.addQueue);

	// Widgets store
	const widgetsPatchWeather = useStore.widgets((state) => state.patchWeather);
	const widgetsPatchNews = useStore.widgets((state) => state.patchNews);

	useEffect(() => {
		setInterval(() => {
			setTime(dayjs().format('h:mm'));
			setDate(dayjs().format('dddd, D MMMM'));
		}, 60000);

		window.addEventListener('keydown', event => {
			setTimeout(() => {
				if (!anyKeyed) {
					setAnyKeyed(true);
					setTimeout(() => {
						if (document.querySelector('input[type=password]')) {
							document.querySelector('input[type=password]').focus();
						}
					}, 100);
				}
			}, 100);
			
			if (event.key === 'Enter') { 
				setWrongPassword(false);
				setTimeout(() => {
					if (document.querySelector('input[type=password]')) {
						document.querySelector('input[type=password]').focus();
					}
				}, 100);
			}
		});

		window.addEventListener('click', event => {
			if (anyKeyed) {
				return;
			}
			
			setTimeout(() => {
				setAnyKeyed(true);
				setTimeout(() => {
					if (document.querySelector('input[type=password]')) {
						document.querySelector('input[type=password]').focus();
					}
				}, 100);
			}, 100);
		});

		let timeoutWeather = setTimeout(() => {
			widgetsPatchWeather('Error getting forecast');
		}, 10000);

		let timeoutNews = setTimeout(() => {
			widgetsPatchNews('Error getting news');
		}, 10000);

		const fetchWeather = async () => {
			fetch(`https://api.yohanesmar.io/weather`)
				.then(response => response.json())
				.then(data => {
					widgetsPatchWeather(data);
					clearTimeout(timeoutWeather);
				});
		}

		const fetchNews = async () => {
			fetch(`https://api.yohanesmar.io/news`)
				.then(response => response.json())
				.then(data => {
					widgetsPatchNews(data);
					clearTimeout(timeoutNews);
				});
		}

		fetchWeather()
			.catch(error => {
				widgetsPatchWeather('Error getting forecast');
			})

		fetchNews()
			.catch(error => {
				widgetsPatchNews('Error getting news');
			})
	}, []);

	const handleClick = (action) => {
		switch (action) {
			case 'password-submit':
				const input = document.querySelector('input[type=password]').value;
				if (input === 'guest') {
					setHiding(true);
					setTimeout(() => {
						globalPatchIsLoggedIn(true);
					}, 200);
				} else {
					setWrongPassword(true);
				}
				break;
			case 'try-again':
				setWrongPassword(false);
				break
		}
	}

	const handleKeyPress = (event, action) => {
		switch (action) {
			case 'password-submit':
				if (event.key === 'Enter') {
					const input = document.querySelector('input[type=password]').value;
					if (input === 'guest') {
						setHiding(true);
						setTimeout(() => {
							globalPatchIsLoggedIn(true);
							
							setTimeout(() => {
								notificationAddQueue(
									{
										application: 'mail',
										label: 'Mail',
										icon: 'mail',
										content: <>
											<p style={{fontSize: '13px', fontWeight: '600', marginBottom: '3px'}}>Yohanes Mario</p>
											<p style={{fontSize: '13px'}}>Hi, I'm Yohan! I'm the Lead Front-end Developer at Boss Capital Holdings, where I utilise full-stack technologies to turn ideas into reality.</p>
										</>,
										notificationSpecific: { startMail: 1 }
									}
								)
							}, 2000);
						}, 200);
					} else {
						setWrongPassword(true);
					}
				}
				break;
		}
	}

	return (
		<>
			<div className={`lockscreen ${hiding ? 'hiding' : ''}`}>
				<div className={`background-overlay ${anyKeyed ? 'zoomed' : ''}`}></div>

				{anyKeyed ? 
					<>
						<div className={`login-container ${anyKeyed ? 'show' : ''}`}>
							<div className='picture-container'>
								<img src='/img/application/mail/accounts.svg' />
							</div>
							<p className='username'>Guest</p>

							<div className='switch-container'>
								{!wrongPassword ?
									<>
										<div className='password-container'>
											<input className='password' type='password' placeholder='Password' onKeyPress={(event) => handleKeyPress(event, 'password-submit')}></input>
											<div className='button-container login' onClick={() => handleClick('password-submit')}>
												<img src='/img/desktop/arrow.svg' />
											</div>
										</div>
										<p className='hint'>Password hint: guest</p>
									</> 
								:
									<>
										<p className='bad-password'>The password is incorrect. Try again.</p>
										<div className='ok-container' onClick={() => handleClick('try-again')}>
											<div className='ok-inner-container'>
												<span>OK</span>
											</div>
										</div>
									</>
								}	
							</div>
						</div>
					</>
					:
					<>
						<div className='spotlight-container'>
							<div className='datetime'>
								<p className='time'>{time}</p>
								<p className='date'>{date}</p>
							</div>

							<div className='mail'>
								<img src='/img/desktop/mail.svg' />
								<p>3</p>
							</div>
						</div>
					</>
				}
			</div>
		</>
	);
}

export default Lockscreen;