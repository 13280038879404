import React from 'react';
import './taskbar.scss';
import useStore from '../../useStore';
import TaskbarItem from './taskbar-item/TaskbarItem';
import SystemTrayItem from './system-tray-item/SystemTrayItem';

function Taskbar(props) {
	// Global store
	const globalDatetime = useStore.global((state) => state.datetime);

	// StartMenue store
	const startMenuOpen = useStore.startMenu((state) => state.open);
	const startMenuPatchOpen = useStore.startMenu((state) => state.patchOpen);
	const startMenuPatchWaiting = useStore.startMenu((state) => state.patchWaiting);

	const handleClick = (event, action = false) => {
		event.stopPropagation();
		
		switch (action) {
			case 'toggle-start-menu':
				if (startMenuOpen) {
					startMenuPatchWaiting(true);
					setTimeout(() => {
						startMenuPatchOpen(true);
						startMenuPatchWaiting(false);
					}, 200);
				} else {
					startMenuPatchOpen();
				}
				break;
		}
	}

	const handleMouseDown = (event) => {
		event.stopPropagation();
	}

	// Applications store
	const applicationRunning = useStore.applications((state) => state.running);

	const taskbarItems = applicationRunning.map(application => {
		return <TaskbarItem key={application.id} id={application.id} name={application.name} />
	})

	return (
		<>
			<div className='taskbar' onClick={(event) => handleClick(event)} onMouseDown={(event) => handleMouseDown(event)}>
               	<div className={`start-menu-button ${startMenuOpen ? 'open' : ''}`} onClick={(event) => handleClick(event, 'toggle-start-menu')}>
					<img src='/img/icon/windows-10.svg' />
				</div>

				<div className='items'>
					{taskbarItems}
				</div>

				<div className='system-tray'>
					<div className='icons'>
						<SystemTrayItem name='windows-security' title='Windows Security' />
						<SystemTrayItem name='wifi' title='Internet access' />
						<SystemTrayItem name='volume' title='Speakers: 100%' />
					</div>

					<div className='datetime' title={globalDatetime.dateLong}>
						<p className='time'>{globalDatetime.time}</p>
						<p className='date'>{globalDatetime.date}</p>
					</div>
				</div>
			</div>
		</>
	);
}

export default Taskbar;