import React from 'react';
import './icon.scss';
import useStore from '../../useStore';

function Icon(props) {
	let name = props.name;
	let label = props.label;
	let type = props.type;
	let isShortcut = props.isShortcut;
	let url = props.url;

	// Global store
	const globalTotalWindowCount = useStore.global((state) => state.totalWindowCount);
	const globalPatchTotalWindowCount = useStore.global((state) => state.patchTotalWindowCount);

	// StartMenu store
	const startMenuPatchOpen = useStore.startMenu((state) => state.patchOpen);
	const startMenuPatchWaiting = useStore.startMenu((state) => state.patchWaiting);

	// Icons store
	const iconSelected = useStore.icons((state) => state.selected);
	const iconSetSelected = useStore.icons((state) => state.setSelected);

	// Applications store
	const applicationAddRunning = useStore.applications((state) => state.addRunning);
	
	const handleClick = (event) => {
		event.stopPropagation();

		startMenuPatchWaiting(true);
		setTimeout(() => {
			startMenuPatchOpen(true);
			startMenuPatchWaiting(false);
		}, 200);
		
		iconSetSelected(name);
	}

	const handleDoubleClick = () => {
		applicationAddRunning({ name: name, order: globalTotalWindowCount, label: label, appSpecific: null });
		globalPatchTotalWindowCount();

		iconSetSelected(null);
	}

	const handleDoubleClickAlternate = () => {
		window.open(url);
	}

	const doubleClick = isShortcut ? handleDoubleClickAlternate : handleDoubleClick;

	return (
		<>
			<div className={`desktop-icon ${iconSelected == name ? 'highlight' : ''}`} onClick={(event) => handleClick(event)} onDoubleClick={doubleClick}>
                <img className='image' src={`/img/icon/${type === 'folder' ? 'folder' : name}.svg`}></img>
				<span className='label'>{label}</span>
			</div>
		</>
	);
}

export default Icon;