import React from 'react';
import './start-menu.scss';
import useStore from '../../../useStore';
import Widgets from './widgets/Widgets';

function StartMenu(props) {
	// Global store
	const globalTotalWindowCount = useStore.global((state) => state.totalWindowCount);
	const globalPatchTotalWindowCount = useStore.global((state) => state.patchTotalWindowCount);

	// StartMenu store
	const startMenuOpen = useStore.startMenu((state) => state.open);
	const startMenuPatchOpen = useStore.startMenu((state) => state.patchOpen);
	const startMenuWaiting = useStore.startMenu((state) => state.waiting);
	const startMenuPatchWaiting = useStore.startMenu((state) => state.patchWaiting);

	// Applications store
	const applicationAddRunning = useStore.applications((state) => state.addRunning);

	// Mail store
	const mailInbox = useStore.mail((state) => state.inbox);

	const handleClick = (event, action, application, applicationLabel) => {
		event.stopPropagation();

		switch (action) {
			case 'open':
				startMenuPatchWaiting(true);
				setTimeout(() => {
					startMenuPatchOpen(true);
					startMenuPatchWaiting(false);
				}, 200);

				if (application == 'Mail') {
					applicationAddRunning({ name: application, order: globalTotalWindowCount, label: applicationLabel, appSpecific: { startMail: mailInbox[1] } });
				} else {
					applicationAddRunning({ name: application, order: globalTotalWindowCount, label: applicationLabel });
				}
				
				globalPatchTotalWindowCount();

				break;
		}
	}

	const handleMouseDown = (event) => {
		event.stopPropagation();
	}

	return (
		<>
			<div className={`start-menu ${startMenuWaiting ? 'closing' : ''}`} onClick={(event) => handleClick(event)} onMouseDown={(event) => handleMouseDown(event)}>
				<div className='sidebar'>
					<div className='button account'>
						<div className='icon-container'>
							<img src='/img/application/mail/accounts.svg'></img>
						</div>

						<p className='label'>Guest</p>
					</div>

					<div className='button shutdown'>
						<div className='icon-container'>
							<img src='/img/start-menu/sidebar/shutdown.svg'></img>
						</div>

						<p className='label'>Power</p>

						<a className='stretched-link' href='https://www.linkedin.com/in/ymario-dev/'></a>
					</div>

					{/* <a className='button shutdown' href='https://www.linkedin.com/in/ymario-dev/'>
						<img src='/img/start-menu/sidebar/shutdown.svg'></img>
					</a> */}
				</div>

				<div className='items'>
					{/* A */}
					{/* <div className='letter'>
						<div className='alphanumeric'>
							<p>A</p>
						</div>

						<div className='item'>
							<div className='icon'>
								<img src='/img/icon/adobe-acrobat.svg' />
							</div>

							<div className='label'>
								<p>Adobe Acrobat</p>
							</div>
						</div>
					</div> */}

					{/* G */}
					<div className='letter'>
						<div className='alphanumeric'>
							<p>G</p>
						</div>

						<div className='item' onClick={(event) => handleClick(event, 'open', 'google-chrome', 'Google Chrome')}>
							<div className='icon'>
								<img src='/img/icon/google-chrome.svg' />
							</div>

							<div className='label'>
								<p>Google Chrome</p>
							</div>
						</div>
					</div>

					{/* M */}
					<div className='letter'>
						<div className='alphanumeric'>
							<p>M</p>
						</div>

						<div className='item' onClick={(event) => handleClick(event, 'open', 'mail', 'Mail')}>
							<div className='icon'>
								<img src='/img/icon/mail.svg' />
							</div>

							<div className='label'>
								<p>Mail</p>
							</div>
						</div>
					</div>

					{/* P */}
					<div className='letter'>
						<div className='alphanumeric'>
							<p>P</p>
						</div>

						<div className='item' onClick={(event) => handleClick(event, 'open', 'paint', 'Paint')}>
							<div className='icon'>
								<img src='/img/icon/paint.svg' />
							</div>

							<div className='label'>
								<p>Paint</p>
							</div>
						</div>
					</div>
				</div>

				<div className='widgets'>
					<Widgets />
				</div>
			</div>
		</>
	);
}

export default StartMenu