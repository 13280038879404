import create from 'zustand';
import produce from 'immer';
import dayjs from 'dayjs';

const useStore = {
	global: create(set => ({
		isLoggedIn: false,
		totalWindowCount: 0,
		datetime: {
			date: dayjs().format('D/MM/YYYY'),
			dateLong: dayjs().format('dddd, D MMMM YYYY'),
			time: dayjs().format('h:mm A')
		},
		patchTotalWindowCount: () =>
			set(produce((draft) => {
				draft.totalWindowCount++;
			})
		),
		patchDatetime: () =>
			set(produce((draft) => {
				draft.datetime.date = dayjs().format('D/MM/YYYY');
				draft.datetime.dateLong = dayjs().format('dddd, D MMMM YYYY');
				draft.datetime.time = dayjs().format('h:mm A');
			})
		),
		patchIsLoggedIn: (value) =>
			set(produce((draft) => {
				draft.isLoggedIn = value;
			})
		)
	})),
	notifications: create(set => ({
		queue: [],
		count: 0,
		addQueue: (notification) =>
			set(produce((draft) => {
				draft.queue.push({
					id: draft.count++,
					application: notification.application,
					label: notification.label,
					icon: notification.icon,
					content: notification.content,
					notificationSpecific: notification.notificationSpecific
				});
			})
		),
		removeQueue: (id) =>
			set(produce((draft) => {
				const index = draft.queue.findIndex(notification => notification.id === id);
				draft.queue.splice(index, 1);
			})
		)
	})),
	startMenu: create(set => ({
		open: false,
		waiting: false,
		patchOpen: (close = false) =>
			set(produce((draft) => {
				if (close) {
					draft.open = false;
				} else {
					draft.open = !draft.open;
				}
			})
		),
		patchWaiting: (option) =>
			set(produce((draft) => {
				draft.waiting = option;
			})
		),
	})),
	icons: create(set => ({
		selected: null,
		setSelected: (name) => set((state) => ({ selected: name })),
	})),
	applications: create(set => ({
		running: [],
		count: 0,
		addRunning: (application) =>
			set(produce((draft) => {
				draft.running.push({
					id: draft.count++,
					name: application.name,
					label: application.label,
					appSpecific: application.appSpecific,
					maximised: false,
					maximiseLabel: 'maximise',
					minimised: false,
					unminimising: false,
					order: application.order,
					opening: true,
					waiting: false
				});
			})
		),
		removeRunning: (id) =>
			set(produce((draft) => {
				const index = draft.running.findIndex(application => application.id === id);
				draft.running.splice(index, 1);
			})
		),
		patchLabel: (application) =>
			set(produce((draft) => {
				const patchingApplication = draft.running.find(running => running.id === application.id);
				patchingApplication.label = application.label;
			})
		),
		patchMaximised: (id) =>
			set(produce((draft) => {
				const patchingApplication = draft.running.find(running => running.id === id);
				patchingApplication.maximised = !patchingApplication.maximised;
				patchingApplication.maximiseLabel = patchingApplication.maximised ? 'resize' : 'maximise';
			})
		),
		patchMinimised: (application) =>
			set(produce((draft) => {
				const patchingApplication = draft.running.find(running => running.id === application.id);
				patchingApplication.minimised = application.minimised;
			})
		),
		patchUnminimising: (application) =>
			set(produce((draft) => {
				const patchingApplication = draft.running.find(running => running.id === application.id);
				patchingApplication.unminimising = application.unminimising;
			})
		),
		patchOrder: (application) =>
			set(produce((draft) => {
				const patchingApplication = draft.running.find(running => running.id === application.id);
				patchingApplication.order = application.order;
			})
		),
		patchOpening: (id) =>
			set(produce((draft) => {
				const patchingApplication = draft.running.find(running => running.id === id);
				if (patchingApplication) {
					patchingApplication.opening = false;
				}
			})
		),
		patchWaiting: (id) =>
			set(produce((draft) => {
				const patchingApplication = draft.running.find(running => running.id === id);
				patchingApplication.waiting = true;
			})
		)
	})),
	mail: create((set, get) => ({
		inbox: [
			{
				id: 0,
				sender: ['Yohanes', 'Mario'],
				subject: "About me",
				time: dayjs().subtract(1, 'hour').subtract(15, 'minute').format('D/MM/YYYY h:mm A'),
				read: false,
				content: 
				`Hi! 
				
				I'm Yohan, Lead Front-end Developer at the BOSSCAP Group. I specialise in using full-stack technologies to create innovative and user-friendly digital solutions. With a passion for turning concepts into functional and engaging experiences, I am dedicated to delivering high-quality results for our projects.
				
				Cheers,
				Yohan`
			},
			{
				id: 1,
				sender: ['Yohanes', 'Mario'],
				subject: "My work",
				time: dayjs().subtract(1, 'hour').subtract(15, 'minute').format('D/MM/YYYY h:mm A'),
				read: false,
				content: 
				`I work in product development, so I'm unable to share my professional work. However, I'd be happy to show you some of my hobby projects! You can check them out by opening Chrome or GitHub.

				Cheers,
				Yohan`
			},
			{
				id: 2,
				sender: ['Yohanes', 'Mario'],
				subject: "How to contact me",
				time: dayjs().subtract(1, 'hour').subtract(15, 'minute').format('D/MM/YYYY h:mm A'),
				read: false,
				content: 
				`You can easily reach me by clicking the "New mail" button on the left. Don't forget to include your reply email address!

				Cheers,
				Yohan`
			}
		],
		drafts: [],
		sent: [],
		inboxCount: 3,
		draftCount: 0,
		sentCount: 0,
		addInbox: (mail) =>
			set(produce((draft) => {
				draft.inbox.push({
					id: draft.inboxCount++,
					sender: mail.sender,
					subject: mail.subject,
					time: mail.time,
					read: false,
					content: mail.content
				});
			})
		),
		addDraft: (mail) =>
			set(produce((draft) => {
				draft.drafts.push({
					id: draft.draftCount++,
					from: mail.from,
					subject: mail.subject,
					content: mail.content,
					time: mail.time
				});
			})
		),
		addSent: (mail) =>
			set(produce((draft) => {
				draft.sent.push({
					id: draft.sentCount++,
					from: mail.from,
					subject: mail.subject,
					content: mail.content,
					time: mail.time
				});
			})
		),
		patchDraft: (mail) =>
			set(produce((draft) => {
				const patchingMail = draft.drafts.find(drafts => drafts.id === mail.id);
				patchingMail.from = mail.from;
				patchingMail.subject = mail.subject;
				patchingMail.content = mail.content;
				patchingMail.time = mail.time;
			})
		),
		removeDraft: (id) =>
			set(produce((draft) => {
				const index = draft.drafts.findIndex(drafts => drafts.id === id);
				draft.drafts.splice(index, 1);
			})
		),
		patchRead: (id) =>
			set(produce((draft) => {
				const patchingMail = draft.inbox.find(inbox => inbox.id === id);
				patchingMail.read = true;
			})
		)
		,
		getUnread: () => {
			return get().inbox.filter(mail => !mail.read).length;
		},
		getInboxCount: () => {
			return get().inboxCount;
		}
	})),
	widgets: create((set, get) => ({
		weather: null,
		news: null,
		patchWeather: (weather) =>
			set(produce((draft) => {
				draft.weather = weather;
			}),
		),
		patchNews: (news) =>
			set(produce((draft) => {
				draft.news = news;
			}),
		),
	}))
}

export default useStore;